import React from "react"
//import { navigate } from "gatsby"
import { getProfile} from "../utils/auth"


const Profile = () => (
  <>
    <h1>Private data: Your profile</h1>
    <img src="https://www.polytechnique.org/photo/quoc-anh.tran.1962" alt="x.org"/>
    <ul>
      <li>Name: {getProfile().name}</li>
      <li>E-mail: {getProfile().email}</li>
      <li>Auth0 Client Domain: {process.env.AUTH0_DOMAIN} </li>
      <li>Auth0 Client ID: {process.env.AUTH0_CLIENTID}</li>
      <li>Auth0 Client Callback: {process.env.AUTH0_CALLBACK}</li>
    </ul>
  </>
)

export default Profile