// src/pages/account.js
import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateroute"
import Profile from "../components/profile"

const Logout = () =>{
  logout()
  return
}
export const Login = ({user}) => { 
  return <p>{user.name ? "Connecté: "+user.name : "non connecté!"}</p>
}

const Account = () => {

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()
  


  return (
    <Layout>
    <>
      <nav>
        <Link to="/account/user" style={{ display: `inline-block`, marginRight: `1rem` }}>Connecté</Link>{" "}
        <Link to="/account/settings" style={{ display: `inline-block`, marginRight: `1rem`}} >Param.</Link> {" "}
        <Link to="/blog/membres" style={{ display: `inline-block`, marginRight: `1rem`}} >Ax-liste</Link> {" "}
        <Link to="/blog/carmel" style={{ display: `inline-block`, marginRight: `1rem`}} >Bureau</Link> {" "}
        <Link to="/blog/fideles" style={{ display: `inline-block`, marginRight: `1rem`}} >Xnet-liste</Link> {" "}
        <Link to="/blog/dcd2020" style={{ display: `inline-block`, marginRight: `1rem`}} >DCD 2020</Link> {" "}
        <Link to="/account/logout" style={{ display: `inline-block`, marginRight: `1rem` }}>Déconnexion</Link>
      </nav>
      
      <Router>
        <Login path="/account/user" user={user}  />
        <PrivateRoute path="/account/settings" component={Profile} />
        <Logout path="/account/logout" />      
      </Router>
    </>
    </Layout>
  )

}

export default Account
